@import "styles/_variable.scss";

.about {
  display: flex;
  flex-wrap: wrap;
  .block {
    position: relative;
    top: 80px;
    right: 80px;
    transition: 1s;
    opacity: 0;
  }
  .animation {
    top: 0;
    right: 0;
    transition: 1s;
    opacity: 1;
  }
  .blockTwo {
    position: relative;
    bottom: 80px;
    left: 80px;
    transition: 1s;
    opacity: 0;
    align-self: flex-start;
  }
  .animationTwo {
    bottom: 0;
    left: 0;
    transition: 1s;
    opacity: 1;
  }
  .slider {
    margin-top: -20px;
  }
  .desc {
    max-width: 538px;
    p {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .pic {
    position: relative;
    img {
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 190px;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(30, 50, 75, 0) 0%, #1e324b7a 100%);
    }
  }
  .title {
    font-size: 40px;
    font-family: $font-geo;
    position: absolute;
    left: 36px;
    top: 32px;
    color: #fff;
    font-weight: 300;
    &.dark {
      color: $color-blue-dark;
    }
  }
  .button {
    position: absolute;
    left: 24px;
    bottom: 20px;
    color: #fff;
    z-index: 1;
  }
  .counter {
    position: absolute;
    right: 32px;
    bottom: 32px;
    color: #fff;
    z-index: 1;
  }
}

.section {
  display: flex;
  margin-bottom: 40px;
  z-index: 1;
}

@media all and (max-width: 1439px) {
  .desc {
    p {
      max-width: 400px;
    }
  }
}

@media all and (max-width: 940px) {
  .about {
    .slider {
      margin-top: 0;
    }
    .title {
      font-size: 36px;
      font-weight: 300;
    }
    .section {
      align-items: flex-start;
    }
  }
}

@media all and (max-width: 700px) {
  .section {
    padding: 0;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .about {
    .desc {
      max-width: 100%;
      p {
        max-width: 100%;
      }
    }
    .button {
      padding-top: 13px;
      padding-bottom: 13px;
      bottom: 50px;
      font-size: 14px;
      line-height: 1.5;
      height: auto;
    }
    .counter {
      bottom: 62px;
    }
    .slider {
      padding: 0;
    }
    .animation {
      transition: 0.3s;
    }
  }
}

@media all and (max-width: 420px) {
  .about .button {
    width: calc(100% - 110px);
    text-align: center;
  }
}